import axios from '@axios'

export default {
  namespaced: true,
  state: {
      user: JSON.parse(localStorage.getItem('userData')) ,
      client:[
        {
          action: "read",
          subject: "ACL",
        },
        {
          action: "read",
          subject: "Auth",
        },
      ],
      admin:[
        {
          action: "manage",
          subject: "all",
        },
      ],
      companyId:0,
      companyName:"",
      title:"",
      routerName:"",

  },
  getters: {},
  mutations: {

    UPDATE_COMPAMYID(state, companyId) {
      state.companyId = companyId
    },
    UPDATE_COMPAMY(state, companyName) {
      state.companyName = companyName
    },
    UPDATE_JOB(state, title) {
      state.title = title
    },
    UPDATE_ROUTER(state, routerName) {
      state.routerName = routerName
    },
    UPDATE_USER(state, user) {
      state.user = user
      localStorage.setItem('userData',JSON.stringify( user))

    },
  
  },
  actions: {

    fetchUser(context,{ id }) {


          axios
          .get(`${process.env.VUE_APP_BASE_URL}/api/User/${id}`)
          .then(response => context.commit("UPDATE_USER", {
            fullName: response.data.data.fullName,
            email: response.data.data.email,
            role: response.data.data.userRoles[0].roleName,
            avatar: response.data.data.avatarFileUrl,
            username: response.data.data.fullName,
            userId: response.data.data.id,
            extras: {
              eCommerceCartItemsCount: 5,
            },
            ability:
              response.data.data.userRoles[0].roleName === "Administrator"
                ? [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ]
                : [
                  {
                    action: "read",
                    subject: "ACL",
                  },
                  {
                    action: "read",
                    subject: "Auth",
                  },
                ],
          }))
          .catch(error => console.log(error))
  },


  },
}
