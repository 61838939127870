export default {
  // Endpoints
  loginEndpoint: `${process.env.VUE_APP_BASE_URL}/api/Auth/login`,
  registerEndpoint: '',
  refreshEndpoint: `${process.env.VUE_APP_BASE_URL}/api/Auth/refresh`,
  logoutEndpoint: `${process.env.VUE_APP_BASE_URL}/api/Auth/revoke`,
  forgotPassEndpoint: `${process.env.VUE_APP_BASE_URL}/api/Auth/password-recovery`,
  recoveryPassEndpoint: `${process.env.VUE_APP_BASE_URL}/api/Auth/confirm-password-recovery`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
