export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Calendar',
          to: '/apps/calendar',
          
        },
     
      ],
    },
  },



  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Todo',
          to: '/apps/todo',
          
        },
     
      ],
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Todo',
          to: '/apps/todo',
          
        },
     
      ],
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Todo',
          to: '/apps/todo',
       
        },
     
      ],
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },


  {
    path: '/apps/list',
    name: 'apps-list',
    component: () => import('@/views/apps/list/users-list/UsersList.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Candidate',
          to: '/apps/list',
          
        },
     
      ],
    },
  },
  {
    path: '/apps/findcondidate/:id',
    name: 'apps-findcondidate',
    component: () => import('@/views/apps/list/users-list/FindCondidate.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Candidate',
          to: '/apps/list',
          
        },
        {
          text: 'Edit',
          active: true,
          
        },
     
      ],
    },
  },

  {
    path: '/apps/view/:id',
    name: 'apps-view',
    component: () => import('@/views/apps/list/users-view/UsersView.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Candidate',
          to: '/apps/list',
          
        },
        {
          text: 'Edit',
          active: true,
          
        },
     
      ],
    },
  },
  {
    path: '/apps/edit/:id',
    name: 'apps-edit',
    component: () => import('@/views/apps/list/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Candidate',
          to: '/apps/list',
          
        },
        {
          text: 'Edit',
          active: true,
          
        },
     
      ],
    },
  },
     // *===============================================---*
  // *--------- Companies ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/companies/list',
    name: 'companies-list',
    component: () => import('@/views/apps/companies/company-list/CompanyList.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/apps/companies/list',
          
        },
      
     
      ],
    },
  },
  {
    path: '/apps/companies/view/:id',
    name: 'companies-view',
    component: () => import('@/views/apps/companies/company-view/CompanyView.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/apps/companies/list',
          
        },
        {
          text: 'Edit',
          active: true,
          
        },
     
      ],
    },
  },
  {
    path: '/apps/companies/candidate/:id',
    name: 'companies-candidate',
    component: () => import('@/views/apps/companies/company-view/CompanyCandidate.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/apps/companies/list',
          
        },
        {
          text: 'Kanban',
          active: true,
          
        },
     
      ],
    },
  },
  {
    path: '/apps/companies/edit/:id',
    name: 'companies-edit',
    component: () => import('@/views/apps/companies/company-edit/CompanyEdit.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/apps/companies/list',
          
        },
        {
          text: 'Positions',
          active: true,
          
        },
     
      ],
    },
  },

       // *===============================================---*
  // *--------- Job ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/job/list',
    name: 'job-list',
    component: () => import('@/views/apps/job/job-list/JobList.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Positions',
          to: '/apps/job/list',
          
        },
      
     
      ],
    },
  },
  {
    path: '/apps/job/view/:id',
    name: 'job-view',
    component: () => import('@/views/apps/job/job-view/JobView.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Positions',
          to: '/apps/job/list',
          
        },
        {
          text: 'Edit',
          active: true,
          
        },
     
      ],
    },
  },
  {
    path: '/apps/job/edit/:id',
    name: 'job-edit',
    component: () => import('@/views/apps/job/job-edit/JobEdit.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Positions',
          to: '/apps/job/list',
          
        },
        {
          text: 'Edit',
          active: true,
          
        },
     
      ],
    },
  },
   // *===============================================---*
  // *--------- USERs ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Users',
          to: '/apps/users/list',
          
        },
      
     
      ],
    },
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Users',
          to: '/apps/users/list',
          
        },
        {
          text: 'Edit',
          active: true,
          
        },
     
      ],
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Users',
          to: '/apps/users/list',
          
        },
        {
          text: 'Edit',
          active: true,
          
        },
     
      ],
    },
  },
//============================================================
{
  path: '/apps/skill',
  name: 'apps-skill',
  component: () => import('@/views/apps/list/users-edit/Skills.vue'),
  meta: {
    pageTitle: '',
    breadcrumb: [
      {
        text: 'Skill',
        to: '/apps/skill',
        
      },
    
   
    ],
  },

},

]
