export default [

  {
    path: '/dashboard',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: 'Dashboard',
    
    },
  },
]
